.tag-list {
	padding: 0;
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	margin-top: 42px;
	margin-bottom: 0;
	gap: 8px;

	@include mobile {
		margin-top: 24px;
		margin-bottom: 32px;
	}
}

.tag-list__link {
	@extend .font-bold;
	height: 32px;
	background: $color-secondary-soft;
	border-radius: 32px;
	display: flex;
	align-items: center;
	padding: 0px 12px;
	font-size: 14px;
	line-height: 28px;
	color: $color-black;
	white-space: nowrap;
	transition: background-color 0.1s;

	&:hover {
		text-decoration: none;
		background: $color-secondary-soft-dark;
	}
}
