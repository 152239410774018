.navbar-subheader {
	position: relative;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		width: 30px;
		height: 100%;
		content: '';
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0) 0%,
			#fcf3f3 100%
		);
	}
}

.navbar-subheader__link {
	font-size: 16px;
	line-height: 1.5;
	position: relative;
	display: inline-block;
	padding: 8px 0 10px 0;
	color: $color-black-soft;

	@include desktop {
		font-size: 18px;
		line-height: 28px;
		padding: 8px 0 15px 0;
	}
}

.navbar-fixed-top {
	&::before {
		content: '';
		background-color: $color-white;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		transition: transform 0.3s, background-color 0.3s;
		box-shadow: inset 0 25px 0 0 white;
	}

	&.sticking::before {
		background-color: #fcf3f3;
		@include desktop {
			background-color: #fcf3f3;
			transform: translateY(-40px);
		}
	}
}
