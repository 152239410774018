.category-slider__wrapper {
	display: grid;
	grid-template-columns: 1fr auto;
}
.category-slider__wrapper .pimcore_editable {
	grid-column: 1 / span 2;
}

.category-slider-headline {
	font-size: 18px;
	grid-column: 1 / span 1;
	display: flex;
	align-items: center;
}

.category-slider__arrows {
	grid-column: 2 / span 1;
	display: flex;
	gap: 8px;
}

.category-slider {
	grid-column: 1 / span 2;
	margin-top: 1rem;
}

.category-slider__item {
	&:hover {
		outline: 0;
		text-decoration: none;

		.category-teaser-media {
			position: relative;
			text-align: center;
		}

		.category-teaser-media:after {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			content: '';
			background: rgba(0, 0, 0, 0.2);
		}
	}
}

.category-slider__item-overline {
	margin-top: 1rem;
	text-decoration: none;
}

.category-slider__item-headline {
	font-size: 22px;
	line-height: 28px;
	margin-top: 0.5rem;
}

.category-teaser-slider {
	.category-teaser-headline {
		line-height: 1.56;
	}
	.category-teaser-description {
		display: none;
	}
}

.category-teaser-slider__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 16px;
	@include tablet-below {
		margin-bottom: 16px;
	}
	@include desktop {
		margin-bottom: 32px;
	}
}

.category-teaser-slider__arrows {
	@include tablet-below {
		display: none;
	}
}

.pimcore_area_teaserslider {
	@include tablet-below {
		margin-top: 32px;
	}
	@include desktop {
		margin-top: 25px;
	}
}
