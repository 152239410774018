.intext-gallery {
  width: 100%;

  &__gallery-counter {
    color: $color-secondary;
    line-height: 1.25;
    margin-bottom: 25px;
    @include font-bold;

    @include tablet-below {
      font-size: 24px;
      line-height: 1;
      display: flex;
      align-items: center;
    }
    @include desktop {
      font-size: 32px;
    }

    &::before {
      content: attr(data-before);
    }
  }

  &__images-slider {
    width: 100%;
  }

  &__image {
    position: relative;
  }

  &__image-item-copyright {
    position: absolute;
    bottom: 8px;
    right: 8px;
    max-width: calc(100% - 16px);
  }

  &__article-caption {
    margin-top: 30px;
    min-height: 32px;

    @include tablet-below {
      font-size: 16px;
    }
    @include desktop {
      font-size: 18px;
    }
  }

  .slick-button {
    --size: 32px;
    top: -45px;
    z-index: 1001;
  }

  .slick-button.slick-prev {
    left: auto;
    right: 46px;
  }

  .slick-button.slick-next {
    right: 10px;
  }
}
