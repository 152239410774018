.category-header-section {
	background-color: #fcf3f3;
	padding-top: 25px;
	padding-bottom: 0;

	@include desktop {
		padding-top: 70px;
		padding-bottom: 0;
	}

	&__inner {
		@include desktop {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr;
			gap: 10px;
		}
	}

	&__link {
		color: #000;

		&:hover {
			text-decoration: none;
		}
	}

	&__headline {
		font-family: SourceSerifPro, serif;
		font-weight: 400;
		line-height: 1.25;
		margin-top: 0;
		margin-bottom: 10px;
		padding-right: 8px;
		padding-top: 4px;

		@include desktop {
			font-size: min(4.65vw, 56px);
			font-weight: bold;
			line-height: 1;
			margin-bottom: 23px;
			padding-top: 18px;
		}
	}

	&__description {
		margin-top: 23px;
		line-height: 1.5;

		@include desktop {
			font-size: 18px;
			line-height: 28px;
		}
	}

	&--author {
		padding-top: 0;
	}

	.category-header-author {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 220px 1fr;
		grid-row-gap: 25px;
		margin: 20px 0;

		@include desktop {
			grid-template-columns: 220px 1fr;
			grid-template-rows: 1fr;
			grid-column-gap: 15px;
			margin: 15px 0;
		}

		&__image {
			max-width: 220px;
			max-height: 220px;
		}

		&__bio {
		}

		&__bio-sub {
		}

		&__bio-name {
			font-size: 1.875rem;
		}
	}

	.navbar-subheader {
		a {
			border-bottom: 4px solid var(--border-color, transparent);
			&:hover {
				text-decoration: none;
				--border-color: rgba(228, 25, 26, 0.3);
				color: $color-black;
			}
		}

		.active {
			--border-color: #{$color-primary};
		}
	}
}
