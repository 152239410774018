//1rem = 16px
//0.0625rem = 1px

$screen-size--desktop: 75rem !default; //1200px
$screen-size--tablet: 48rem !default; //768px
$screen-size--mobile: 25.875rem !default; //462px

//768px - 1px
//767px
@mixin mobile {
	@media (max-width: $screen-size--tablet - 0.0625rem) {
		@content;
	}
}

//min-width:768px and max-width: 1199px
@mixin tablet {
	@media (min-width: $screen-size--tablet) and (max-width: $screen-size--desktop - 0.0625rem) {
		@content;
	}
}

//1200px
@mixin desktop {
	@media (min-width: $screen-size--desktop) {
		@content;
	}
}

// above
//768px
@mixin tablet-above {
	@media (min-width: $screen-size--tablet) {
		@content;
	}
}

// below
//1200px - 1px
//1199px
@mixin tablet-below {
	@media (max-width: $screen-size--desktop - 0.0625rem) {
		@content;
	}
}
