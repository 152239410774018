.u-header-slider__wrapper {
	position: relative;

	@include mobile {
		padding-left: 0;
		padding-right: 0;
	}

	.img-responsive {
		width: 100%;
		object-fit: cover;
	}

	.header-slider-background__image--mobile {
		aspect-ratio: 480 / 713;
		@include desktop {
			display: none;
		}
	}
	.header-slider-background__image--desktop {
		aspect-ratio: 1140 / 641;
		@include tablet-below {
			display: none;
		}
	}
}

.header-slider--background {
	z-index: 1;
	@include desktop {
		.header-media-caption {
			display: none;
		}
	}
	@include tablet-below {
		.header-media-caption {
			background: rgba(6, 6, 6, 0.7);
			padding: 24px 20px 52px 20px;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}

		.slick-dots {
			position: absolute;
			bottom: 28px;
		}

		.slick-next {
			top: 33%;
			right: 23px;
			z-index: 1;
		}

		.slick-prev {
			top: 33%;
			left: 23px;
			z-index: 1;
		}

		$button-size: 8px;

		.slick-dots li {
			height: $button-size;
			width: $button-size;
		}

		.slick-dots li button {
			width: $button-size;
			height: $button-size;
			&:before {
				width: $button-size;
				height: $button-size;
				font-size: $button-size;
				line-height: $button-size;
			}
		}

		.header-media-caption-headline {
			font-size: 12px;
			line-height: 1.33;
		}

		.header-media-caption-subline {
			font-size: 25px;
			line-height: 1.25;

			@include desktop {
				font-size: 32px;
			}
		}

		.header-media-caption-headline + .header-media-caption-subline {
			margin-top: 4px;
		}
	}
}
#page .header-slider--background {
	@include desktop {
		--inline-padding: calc((100vw - 1170px - var(--scrollbar-width)) / 2);
		margin-inline: calc(-1 * var(--inline-padding));
		padding-inline: var(--inline-padding);
		overflow: hidden;

		.slick-list {
			overflow: visible;
			z-index: 1;
		}

		&::before,
		&::after {
			content: '';
			width: var(--inline-padding);
			z-index: 2;
			background-color: rgba(0, 0, 0, 0.7);
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			cursor: pointer;
		}
		&::before {
			left: 0;
			border-right: 2px solid white;
		}
		&::after {
			right: 0;
			border-left: 2px solid white;
		}
	}
}

.u-header-slider__text-slider-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	background: rgba(6, 6, 6, 0.7);
	padding: 15px;
	position: absolute;
	bottom: 0;
	z-index: 2;

	@include tablet-below {
		display: none;
	}

	@include desktop {
		padding: 32px;
		right: 15px;
		left: 15px;
	}
}
.header-slider--text {
	width: 100%;
	display: flex;

	.slick-list {
		width: 100%;
		order: 1;
		margin-right: 1.5rem;
	}

	.slick-prev,
	.slick-next {
		position: relative;
		transform: initial;
		top: 0;
		left: 0;
		right: 0;
	}

	.slick-prev {
		order: 2;
		margin-right: 0.5rem;
	}
	.slick-next {
		order: 3;
	}

	a.header-media-caption:hover,
	a.header-media-caption:focus {
		outline: 0;
		text-decoration: none;
	}
	a.header-media-caption:focus-visible {
		.header-media-caption-subline {
			color: $color-secondary-dark;
		}
	}

	.header-media-caption-headline + .header-media-caption-subline {
		margin-top: 0.5rem;
	}
}
.u-header-slider__text-slider-wrapper {
	/* single rule because of possible browser support issues */
	backdrop-filter: blur(2px);
}
