.article-media__source {
	background-color: $color-black;
	color: rgba($color-white, 0.6);
	opacity: 0.6;
	font-size: 10px;
	line-height: 16px;
	padding: 4px 12px;
	border-radius: 12px;
	position: absolute;
	bottom: 8px;
	right: 8px;

	@include desktop {
		font-size: 12px;
	}
}

.article-media__image-wrapper {
	position: relative;
}

.article-media__caption {
	margin-top: 8px;
}
