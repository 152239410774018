.category-teaser-badge {
	font-size: 14px;
	position: absolute;
	top: 10px;
	left: 25px;
	padding: 0 9px;
	text-transform: uppercase;
	pointer-events: none;
	color: #fff;
	border: 0;
	background-color: var(--theme-primary-color, rgba(0, 131, 151, 0.8));
}

a.affiliate {
	position: relative;
	padding-right: 1.25rem;
}

a.affiliate::before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	width: 0.95rem;
	height: 0.95rem;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --%3E%3Cpath d='M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right;
	background-size: auto 0.95rem;
}

div[id$='Wrapper']:not(#menu1Wrapper):not(#menu2Wrapper):not(#sky1Wrapper):not(
		#outofpageWrapper
	):not(#sb1Wrapper)::before,
div#sb1Wrapper > div::before {
	content: 'ANZEIGE';
	display: block;
	color: rgba(205, 211, 222, 1);
	font-size: 10px;
	letter-spacing: 1px;
	@include font-regular;
	padding: 8px 0;
	text-align: center;
}

.header-media-image {
	position: relative;
}
.header-media-prev,
.header-media-next {
	cursor: pointer;
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 20%;
	height: 100%;
	opacity: 1;
	z-index: 1000;
}
.header-media-image:hover .header-media-prev {
	background: -moz-linear-gradient(
		left,
		rgba(0, 0, 0, 0.05) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	background: -webkit-linear-gradient(
		left,
		rgba(0, 0, 0, 0.05) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	background: linear-gradient(
		to right,
		rgba(0, 0, 0, 0.05) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=1 );
}
.header-media-next {
	left: auto;
	right: 0;
}
.header-media-image:hover .header-media-next {
	background: -moz-linear-gradient(
		left,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.05) 100%
	);
	background: -webkit-linear-gradient(
		left,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.05) 100%
	);
	background: linear-gradient(
		to right,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.05) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=1 );
}
.category-intro-text {
	height: 120px;
	overflow: hidden;
	font-size: 1.1875rem;
	line-height: 1.875rem;
}
.category-intro-text.category-intro-text--off {
	height: auto;
	overflow: visible;
}
.category-intro-text.category-intro-text--off::after {
	display: none;
}
.navbar-subheader::after {
	pointer-events: none;
	width: 30%;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
	z-index: 99999;
}
.product-slider .product-title {
	height: 4.5rem;
	text-overflow: ellipsis;
	overflow: hidden;
}
.isAdvertorial::after {
	font-size: 0.875rem;
	line-height: 1;
	padding: 0.875rem 0.9375rem; /*padding: 14px 15px;*/
}
.isGallery2 {
	position: relative;
	display: block;
}
.isGallery2::before {
	font-size: 0.875rem;
	line-height: 1;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	padding: 0.875rem 0.9375rem;
	content: 'Galerie';
	white-space: nowrap;
	letter-spacing: 0.12em;
	text-transform: uppercase;
	color: #fff;
	background: #bfbfbf;
}
.isAdvertorial.isGallery2::before {
	display: none;
}
@media (min-width: 992px) {
	.navbar-subheader-nav > ul {
		position: relative;
		padding-top: 15px;
	}
	.navbar-subheader-nav > ul > li:last-of-type > a {
		margin-right: 0;
	}
	.ps__rail-x {
		top: 0;
		bottom: auto;
	}
}
.js-toc-show-more-li:hover {
	background: #fafafa;
}
.js-toc-show-more-li .js-toc-show-more {
	padding-left: 14px;
	letter-spacing: 0.12em;
}
.js-data-href {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	text-indent: -999999px;
}
.category-teaser .category-teaser-media:hover::before,
.category-teaser .category-teaser-media:hover::after {
	pointer-events: none;
}
@media (min-width: 768px) {
	.pimcore_area_affiliate .product-media img {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.pimcore_area_affiliate .product-media {
		min-height: 110px;
	}
	.pimcore_area_affiliate .product-media img {
		max-height: 110px;
	}
}
@media (min-width: 992px) {
	.pimcore_area_affiliate .product-media {
		min-height: 160px;
	}
}
.article-gallery--gallery .isGallery2::before {
	display: none;
}

.navbar-subheader > ul > li.active > a.sub-horoskope:after,
.navbar-subheader > ul > li:hover > a.sub-horoskope:after,
.navbar-subheader > ul > li:focus > a.sub-horoskope:after {
	background-color: #c0c0c0;
}
.navbar-subheader.navbar-subheader--horoskope > ul > li.active > a:after,
.navbar-subheader.navbar-subheader--horoskope > ul > li > a:hover:after {
	background-color: #c0c0c0;
}
.category-logo.category-logo--lined.category-logo--horoskope:after {
	background-color: #c0c0c0;
}
@media (min-width: 768px) {
	.category-teasers--horoskope
		.category-teaser
		.category-teaser-media:hover:before {
		background-image: url('/html/images/overlay-line-horoskope.png');
	}
}
.mm-menu.category-horoskope
	.mm-panel_opened:not(#panel-menu)
	.mm-listitem_selected
	> a:not(.mm-btn_next),
.mm-menu .mm-panel_opened:not(#panel-menu) .mm-listitem_selected > span {
	background: #c0c0c0;
}
.mm-navbar__title.category-horoskope,
.header-media-category--horoskope:before {
	background-color: #c0c0c0;
}

.navbar-subheader-nav.navbar-subheader-nav--horoskope > ul > li.active > a,
.navbar-subheader-nav.navbar-subheader-nav--horoskope > ul > li > a:hover {
	border-color: #c0c0c0;
}
.logo--horoskope {
	font-family: 'Rozha One', serif;
	font-size: 60px;
	line-height: 1;
	margin: -10px 0;
	letter-spacing: -0.2rem;
	text-transform: uppercase;
}
.logo--horoskope:after {
	content: ':';
	color: #c0c0c0;
}
.category-teasers .logo--horoskope {
	font-size: 70px;
	position: absolute;
	z-index: 9;
	top: -5px;
	right: 30px;
}

.navbar-subheader > ul > li.active > a.sub-aktionen:after,
.navbar-subheader > ul > li:hover > a.sub-aktionen:after,
.navbar-subheader > ul > li:focus > a.sub-aktionen:after {
	background-color: #721193;
}
.navbar-subheader.navbar-subheader--aktionen > ul > li.active > a:after,
.navbar-subheader.navbar-subheader--aktionen > ul > li > a:hover:after {
	background-color: #721193;
}
.category-logo.category-logo--lined.category-logo--aktionen:after {
	background-color: #721193;
}
@media (min-width: 768px) {
	.category-teasers--aktionen
		.category-teaser
		.category-teaser-media:hover:before {
		background-image: url('/html/images/overlay-line-aktionen.png');
	}
}
.mm-menu.category-aktionen
	.mm-panel_opened:not(#panel-menu)
	.mm-listitem_selected
	> a:not(.mm-btn_next),
.mm-menu .mm-panel_opened:not(#panel-menu) .mm-listitem_selected > span {
	background: #721193;
}
.mm-navbar__title.category-aktionen,
.header-media-category--aktionen:before {
	background-color: #721193;
}
.navbar-subheader-nav.navbar-subheader-nav--aktionen > ul > li.active > a,
.navbar-subheader-nav.navbar-subheader-nav--aktionen > ul > li > a:hover {
	border-color: #721193;
}
.logo--aktionen {
	font-family: 'Rozha One', serif;
	font-size: 60px;
	line-height: 1;
	margin: -10px 0;
	letter-spacing: -0.2rem;
	text-transform: uppercase;
}
.logo--aktionen:after {
	content: ':';
	color: #721193;
}
.category-teasers .logo--aktionen {
	font-size: 70px;
	position: absolute;
	z-index: 9;
	top: -5px;
	right: 30px;
}
/* mm.css */

.content--horoskope .pimcore_area_categoryteaser {
	padding-bottom: 30px;
}
.content--horoskope .pimcore_area_categoryteaser .logo--horoskope,
.content--horoskope .pimcore_area_categoryteaser .category-actions {
	display: none;
}
@media (max-width: 767px) {
	.content--horoskope .pimcore_area_teaserslider .header-media-caption,
	.content--horoskope
		.pimcore_area_categoryteaser
		.header-media-category
		.header-media-caption {
		position: static;
	}
	.content--horoskope .pimcore_area_categoryteaser .category-logo,
	.content--horoskope
		.pimcore_area_categoryteaser
		.header-media-category::before {
		display: none;
	}
	.content--horoskope .category-intro-text {
		margin-bottom: 0;
	}
	.content--horoskope .pimcore_area_categoryteaser {
		padding-bottom: 0;
	}
	/*.content--horoskope .pimcore_area_categoryteaser .header-media-category { margin-bottom: 0; }*/
	.content--horoskope .pimcore_area_categoryteaser .header-media-category {
		margin-bottom: 0;
	}
	.content--horoskope .pimcore_area_categoryteaser .sidebar-ads,
	.content--horoskope
		.pimcore_area_categoryteaser
		.header-media-caption-headline {
		display: none;
	}
}
@media (min-width: 768px) {
	.navbar > .container .navbar-brand {
		position: static;
		padding-left: 0;
	}
	#navbar {
		text-align: left;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	.category-teasers.category-teasers--vertical
		> .category-teaser:first-child
		.category-teaser-body {
		margin-left: -50px;
	}
}
.isGallery2::before {
	display: none;
}
.category-ads-left,
.category-ads-right {
	z-index: 3;
}

@media (min-width: 992px) {
	.header-navbar-search-gesund {
		position: absolute;
		top: 4px;
		right: 0;

		content: '';
		display: block;
		width: 100%;
		height: 12px;
		background: url(/html/images/funke-gesundheit2.svg) no-repeat center
			right;
		background-image: url(/logo-funke-gesundheit-2021.svg);
	}
}
@media (max-width: 991px) {
	.gesund-mobile {
		content: '';
		display: block;
		width: 100%;
		height: 18px;
		background: url(/html/images/funke-gesundheit-white2.svg) no-repeat
			center center;
		background-color: #b0afad;
		background-size: auto 11px;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9;
		transform: opacity 0.3s;
		opacity: 1;
		background-image: url(/logo-funke-gesundheit-2021-white.svg);
	}
}

body #pc-cookie-notice {
	background-color: #000;
	line-height: 30px;
}
body #pc-cookie-notice #pc-button {
	top: 4px;
}
body #pc-cookie-notice #pc-button button {
	background: transparent;
	border-color: #fff;
	text-transform: uppercase;
	border-radius: 0px;
	@include font-bold;
	line-height: 20px;
	padding-left: 8px;
	padding-right: 8px;
	font-size: 10px;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	color: #fff;
	cursor: pointer;
}
body #pc-cookie-notice #pc-button button:hover {
	background-color: #fff;
	color: #000;
}
@media (max-width: 750px) {
	body #pc-cookie-notice #pc-message {
		line-height: 22px;
	}
	body #pc-cookie-notice #pc-button {
		right: 10px;
	}
	body #pc-cookie-notice #pc-button button {
		padding: 1px 8px;
	}
	body #pc-cookie-notice #pc-message {
		padding-left: 10px;
	}
}

.teaserslider-by-path .category-teasers {
	margin-top: 0;
}

.append div[id^='nativendo-'] a {
	color: #000;
	text-decoration: none;
}
body .btv_widget .videoBox .infoBox .topTitle,
body .fullPlayer .player > .btv_row > div.infoCol .topTitle {
	color: #000;
}
.gallery-thumbs.slider-nav .slick-slide {
	white-space: nowrap;
}

.content--gallery #sidebar {
	/* 13.05.21 */
	margin-top: 0;
}
.gallery-thumbs {
	margin: 0;
	margin-top: 4px;
	padding-top: 45px;
}
.js-gallery-preview img {
	display: none;
}

@media (min-width: 768px) {
	.category-teasers.category-teasers--margin
		.category-teaser:nth-child(3n + 1) {
		clear: left;
	}
}

.product-inner {
	text-align: center;
	border: 0;
}
.product-slider .slick-next,
.product-slider .slick-prev {
	z-index: 3;
	right: 0;
	width: 30px;
	height: 59px;
	content: '';
	background: #000;
}
.product-slider .slick-next {
	right: 15px;
}
.product-slider .slick-prev {
	right: auto;
	left: 15px;
}
@media (max-width: 767px) {
	.product-slider .slick-next {
		right: 0;
	}
	.product-slider .slick-prev {
		right: auto;
		left: 0;
	}
}
.product-slider .slick-next::before {
	font-family: icons !important;
	font-size: 24px;
	font-weight: normal !important;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	content: '\f102';
	text-transform: none;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.product-slider .slick-prev::before {
	font-family: icons !important;
	font-size: 24px;
	font-weight: normal !important;
	font-style: normal;
	font-variant: normal;
	line-height: 1;
	content: '\f103';
	text-transform: none;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.product-brand {
	font-size: 16px;
}
.product-body .product-title {
	font-size: 19px;
	line-height: 28px;
}
.product-slider .product-title {
	height: auto; /* 56px */
}
.product-price {
	font-size: 16px;
	margin-bottom: 15px;
}
.product-media {
	margin-bottom: 20px;
}
.product-inner:hover .product-media::after {
	display: none;
}
.product {
	padding-right: 45px;
	padding-left: 45px;
}
/*@media (max-width: 767px)
            {
                .product-slider .slick-list
                {
                    padding: 0 30% 0 0;
                }
            }*/
.product {
	padding-bottom: 121px;
}
.product-inner {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}
.product-body {
	position: absolute;
	bottom: 25px;
	left: 0;
	width: 100%;
	margin: 0 auto;
}
.product-price {
	margin-top: 0;
}
@media (min-width: 992px) {
	.pimcore_area_affiliate .product-media {
		min-height: 200px;
	}
	/*.pimcore_area_affiliate .product-media img { min-height: 200px; }*/
	.pimcore_area_affiliate .product-media img {
		max-height: 200px;
	}
}

.pimcore_area_oembed iframe {
	width: 100%;
	min-height: 365px;
}
@media (max-width: 991px) {
	.pimcore_area_oembed iframe {
		width: 100%;
		min-height: 230px;
		height: auto;
	}
}
@media (max-width: 767px) {
	.pimcore_area_oembed iframe {
		width: 100%;
		min-height: 195px;
		height: auto;
		padding-left: 15px;
		padding-right: 15px;
	}
}
@media (max-width: 767px) {
	.header-media iframe {
		max-width: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}
	.header-media iframe[width='600'] {
		width: 375px;
		height: 530px;
		margin: 0 auto;
	}
}
#tvd-voucher-widget {
}
#tvd-voucher-widget .tvd-voucher-widget-headline {
	font-size: 1.5rem;
	font-weight: 400;
	color: #000;
}
#tvd-voucher-widget .tvd-voucher-widget-voucher-headline {
	color: #000;
}
#tvd-voucher-widget .tvd-voucher-widget-cta a {
	font-weight: 400;
	letter-spacing: 0.12em;
	background: #fff;
	border: 2px solid #000;
	color: #000;
	font-size: 0.875rem;
	padding: 20px 35px;
	line-height: 1.3333333;
}
#tvd-voucher-widget .tvd-voucher-widget-cta a:hover {
	background: #000;
	color: #fff;
}
