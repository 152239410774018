@mixin listBorder {
	$stroke: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(0, 0, 0, 0.2)' stroke-width='2' stroke-dasharray='1, 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	list-style: none;
	background-image: $stroke;

	> li {
		position: relative;

		&:not(:last-child)::after {
			content: '';
			background-size: 6px 3px;
			height: 1px;
			background-image: $stroke;
			grid-column: 1 / span 3;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	ol {
		margin-top: var(--spacing);
	}
}

@mixin numberedList {
	@include tablet-below {
		--circle-size: 32px;
		--spacing: 16px;
	}
	@include desktop {
		--circle-size: 40px;
		--spacing: 24px;
	}
	$stroke: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(0, 0, 0, 0.2)' stroke-width='2' stroke-dasharray='1, 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	counter-reset: list;
	list-style: none;
	padding-left: 0;
	background-image: $stroke;

	> li {
		--left-spacing: calc(2 * var(--spacing) + var(--circle-size));
		@extend .p-xl;
		position: relative;
		padding-left: var(--left-spacing);
		padding-right: var(--spacing);
		padding-block: var(--spacing);

		&::before {
			@extend .font-bold;
			counter-increment: list;
			content: counter(list);
			width: var(--circle-size);
			height: var(--circle-size);
			background: #f6e7ec;
			color: $color-secondary;
			display: flex;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			left: var(--spacing);
			top: var(--spacing);
			position: absolute;

			@include tablet-below {
				font-size: 18px;
			}
			@include desktop {
				font-size: 20px;
			}
		}

		&:not(:last-child)::after {
			content: '';
			background-size: 6px 3px;
			height: 1px;
			background-image: $stroke;
			grid-column: 1 / span 3;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}

	ol {
		margin-top: var(--spacing);
	}
}

.pimcore_area_wysiwyg {
	@extend .p-m;
	color: $color-black-light;

	ul li {
		@extend .p-xl;
	}

	ol {
		@include numberedList;
	}

	p {
		@extend .p-xl;
		margin-bottom: 16px;
		color: $color-black-light;
	}

	h2 {
		@extend .h4;
	}
	h3 {
		@extend .h5;
	}

	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3 {
		color: $color-black-soft;

		@include tablet-below {
			margin-top: 32px;
			margin-bottom: 16px;
		}
		@include desktop {
			margin-top: 48px;
			margin-bottom: 22px;
		}

		+ ol {
			@include tablet-below {
				margin-top: 16px;
			}
			@include desktop {
				margin-top: 24px;
			}
		}
	}
}

.tipp {
	background: #faf8e7;
	border-radius: 16px;
	padding: 24px;
	margin-bottom: 16px;
	margin-top: 16px;
}

.pimcore_area_imagegallery + .pimcore_area_wysiwyg {
	margin-top: 24px;
}
