.slick-prev:before,
.slick-next:before {
	content: unset;
}
.slick-prev,
.slick-next {
	--size: 40px;

	color: var(--color);
	width: var(--size);
	height: var(--size);

	svg {
		width: var(--size);
		height: var(--size);
	}

	&:focus,
	&:hover {
		color: var(--color-hover);
	}

	&:focus-visible {
		outline: 2px solid $color-secondary-dark;
		border-radius: 50%;
		circle {
			filter: brightness(0.85);
		}
	}
}
.slick-prev {
	circle {
		opacity: 0.3;
	}
}

.slider-arrows--small {
	display: flex;
	gap: 4px;
	.slick-arrow {
		--size: 32px;
		--color: #ffffff;
		min-height: var(--size);
	}
}

.related-articles {
	@include tablet-below {
		margin-bottom: 46px;
	}
	@include desktop {
		margin-bottom: 58px;
	}
}
