.pimcore_area_newsletterlist {

  margin-bottom: 48px;

  section {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @include desktop {
      margin-bottom: 100px;
    }
  }

  &__header-section .category-header-section {
    padding: 0 31.5px;
  }

  &__headline {

  }

  &__text {
    margin-bottom: 48px;
  }

  &__input {
    margin-bottom: 20px;
  }

  &__accepttext {
    margin-bottom: 48px;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include desktop {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 150px;
    }

    .button--red {
      width: auto;
    }
  }

  &__image-wrapper img {
    margin: auto;
    width: auto;
    height: auto;
    max-width: 467px;
    margin-bottom: 12px;

    @include desktop {
      margin-bottom: 0;
    }
  }
}
