.author-list {
	display: grid;
	grid-template-columns: 1fr;
	gap: 25px 15px;

	@include tablet-above {
		grid-template-columns: repeat(2, 1fr);
	}

	a {
		color: #000;

		&:hover,
		&:active,
		&:focus {
			color: $color-secondary;
			text-decoration: none;
		}
	}

	&__item {
		display: grid;
		gap: 15px;
		grid-template-columns: 33% 66%;
	}

	&__image {
	}

	&__bio {
	}

	&__sub {
		font-size: 1.125rem;
	}

	&__name {
		color: $color-secondary;
		font-size: 1.5rem;
		margin-bottom: 1.125rem;

		@include tablet-above {
			font-size: 1.875rem;
		}

		a & {
			color: $color-secondary;
		}
	}

	&__url {
		text-decoration: none;
	}

	&__description {
		font-size: 1rem;
	}
}
