@font-face {
	font-display: swap;
	font-family: 'Inter-Latin-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('/html/fonts/bdf/inter-v12-latin-regular.woff2') format('woff2');
}

@mixin font-regular() {
	font-family: 'Inter-Latin-Regular', serif;
	font-weight: normal;
}

@font-face {
	font-display: swap;
	font-family: 'Inter-Latin-Bold';
	font-style: normal;
	font-weight: bold;
	src: url('/html/fonts/bdf/inter-v12-latin-600.woff2') format('woff2');
}

@mixin font-bold() {
	font-family: 'Inter-Latin-Bold', serif;
	font-weight: normal;
}

@font-face {
	font-display: swap;
	font-family: 'SourceSerifPro';
	font-style: normal;
	font-weight: normal;
	src: url('/html/fonts/SourceSerif/SourceSerif4SmText-BoldIt.ttf.woff2') format('woff2');
}

.font-regular {
	@include font-regular;
}
.font-bold {
	@include font-bold;
}
.text-small {
	font-size: 13px;
	line-height: 1.23;
}
.text-large {
	font-size: 34px;
	line-height: 1.24;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@include font-bold;
}

h1,
.h1 {
	@include font-bold;
	@include tablet-below {
		font-size: 40px;
		line-height: 1.2;
	}
	@include desktop {
		font-size: 3.5rem;
		line-height: 1.07;
	}
}

h2,
.h2 {
	@include font-bold;
	@include tablet-below {
		font-size: 36px;
		line-height: 1.22;
	}
	@include desktop {
		font-size: 48px;
		line-height: 1.12;
	}
}

h3,
.h3 {
	@include tablet-below {
		font-size: 32px;
		line-height: 1.25;
	}
	@include font-bold;
	@include desktop {
		font-size: 40px;
		line-height: 1.2;
	}
}

h4,
.h4 {
	@include font-bold;
	@include tablet-below {
		font-size: 28px;
		line-height: 1.29;
	}
	@include desktop {
		font-size: 36px;
		line-height: 1.22;
	}
}

h5,
.h5 {
	@include font-bold;
	@include tablet-below {
		font-size: 24px;
		line-height: 1.33;
	}
	@include desktop {
		font-size: 32px;
		line-height: 1.25;
	}
}

h6,
.h6 {
	@include font-bold;
	@include tablet-below {
		font-size: 20px;
		line-height: 1.4;
	}
	@include desktop {
		font-size: 28px;
		line-height: 1.14;
	}
}

.p-xl {
	@include tablet-below {
		font-size: 18px;
		line-height: 1.56;
	}
	@include desktop {
		font-size: 20px;
		line-height: 1.6;
	}
}

.p-l {
	@include tablet-below {
		font-size: 16px;
		line-height: 1.5;
	}
	@include desktop {
		font-size: 18px;
		line-height: 1.56;
	}
}

.p-m {
	@include tablet-below {
		font-size: 14px;
		line-height: 1.43;
	}
	@include desktop {
		font-size: 16px;
		line-height: 1.5;
	}
}

.p-s {
	@include tablet-below {
		font-size: 12px;
		line-height: 1.33;
	}
	@include desktop {
		font-size: 14px;
		line-height: 1.43;
	}
}

.p-xs {
	@include tablet-below {
		font-size: 10px;
		line-height: 1.4;
	}
	@include desktop {
		font-size: 12px;
		line-height: 1.33;
	}
}

.overline {
	@include font-bold;
	text-transform: uppercase;
	color: $color-secondary;

	@include tablet-below {
		font-size: 12px;
		line-height: 1.33;
	}
	@include desktop {
		font-size: 14px;
		line-height: 1.14;
	}
}
