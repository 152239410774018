.toc:not(:first-child) {
	margin-top: 60px;
}

.table-of-contents {
	padding: 0;
	list-style-type: none;
	margin-top: 24px;
	margin-bottom: 0;

	a {
		color: $color-secondary;
		max-width: 283px;
		display: block;

		&:hover {
			color: $color-secondary-dark;
			text-decoration: none;
		}
	}

	li {
		padding-bottom: 16px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	}
	li + li {
		margin-top: 16px;
	}
}
