.recipe {
	&__cookingtime {
		ol {
			@include numberedList;

			p:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__ingredients {
		ul {
			@include listBorder;
			padding-left: 0;
		}

		li {
			@extend .p-l;
			padding: 15px 24px;
		}
	}

	&__cookingtime-item *,
	&__per_portion_item div {
		display: inline-grid;
		grid-template-columns: auto auto;
		grid-gap: 3px;
	}
}
