.input--default {
	padding: 8px 16px 8px 24px;
	background: #ffffff;
	border: 1px solid rgba(6, 6, 6, 0.2);
	border-radius: 40px;
	height: 48px;
	display: flex;
	appearance: none;
}

.input--gray {
	padding: 8px 16px 8px 24px;
	background: #f6f6f6;
	border: 1px solid #f6f6f6;
	border-radius: 40px;
	height: 48px;
	display: flex;
	appearance: none;
	box-shadow: none;
}

.checkbox__label {
	$spacing: 24px;
	padding-left: $spacing;
	display: block;

	input[type='checkbox'] {
		margin-left: -1 * $spacing;
		display: inline-block;
		position: absolute;
		margin-top: 7px;
	}
}
