.teaserlist {
	@include tablet-below {
		margin-top: 48px;
	}
	@include desktop {
		margin-top: 70px;
	}

	a.js-load-more:hover,
	a.js-load-more:active {
		text-decoration: none;
	}
}
