.newsletter-cta-container {
	background: #fcf3f3;
	padding: 32px 24px 40px;
	font-size: 26px;
	line-height: 0.92;
	margin-top: 56px;

	@include desktop {
		border-radius: 16px;
		display: none;
	}

	.button--black {
		margin-top: 16px;
	}

	.input--default {
		margin-top: 32px;
		display: flex;
	}
}
#sidebar .newsletter-cta-container {
	@include desktop {
		display: block;
	}
}

.newsletter-form__details {
	margin-top: 16px;
	font-size: 18px;
	line-height: 1.56;
	opacity: 0.8;

	p:last-child {
		margin-bottom: 0;
	}
}
