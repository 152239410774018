.socials > a {
	display: inline-block;
	font-size: 0;
	margin-right: 8px;
	color: $color-black;
	transition: color 0.1s;
	width: 32px;

	&:hover {
		color: $color-secondary;
	}
}

.socials {
	display: flex;
	flex-wrap: wrap;
	margin-top: 16px;
}
