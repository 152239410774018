.mm-menu {
  background: #fff;
  text-transform: none;

  a,
  a:active,
  a:hover,
  a:link,
  a:visited {
    color: $color-primary;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }

  .mm-listview {
    text-transform: none;
  }

  .mm-listitem {
    padding: 2px 0;

    &.mm-listitem__l0 {
      border-bottom: 1px solid $color-medium-grey;

      a {
        letter-spacing: 0.2px;

        .mm-listitem__text {
          margin-left: 0;
        }
      }
    }

    &.mm-listitem__l1 {
      border-bottom: 1px solid $color-medium-grey;
    }

    &__linkonly {
      a,
      a:active,
      a:hover,
      a:link,
      a:visited {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        letter-spacing: 0.14px;
        padding: 8px 0;
      }
    }

    span {
      color: $color-primary;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      overflow: visible;
      padding: 0;
      margin: 8px 0 8px 0;
      letter-spacing: 0.2px;
    }

    a {
      padding-left: 0;
    }

    .icon-chevron {
      display: block;
      padding-top: 2px;
      padding-bottom: 2px;
      position: relative;
      min-height: 20px;

      &:after {
        position: absolute;
        right: 0;
        top: -2px;
        content:url(/html/images/icon-menu-chevron-up.svg);
        transform: rotate(90deg);
      }
    }

    &_vertical>.mm-panel {
      padding: 0;
    }

    //level 2
    .mm-listitem {
      border-bottom: 1px solid $color-medium-grey;
      padding: 0;

      &:first-child {
        border-top: 1px solid $color-medium-grey;
      }

      &:last-child {
        margin-bottom: 40px;
      }

      a,
      a:active,
      a:hover,
      a:link,
      a:visited {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.14px;
        line-height: 160%;
      }
    }
  }

  .mm-listitem_opened .icon-chevron {
    &:after {
      transform: rotate(0deg);
    }
  }

  .mm-panel_opened:not(#panel-menu) .mm-listitem__l0>a,
  .mm-panel_opened:not(#panel-menu) .mm-listitem__l0>span {
    padding-left: 0;
  }
}

.mm-menu_offcanvas {
  width: 100%;
  z-index: 10; //must be higher than 9 to lay over the ad wrapper with z-index:9;
}

.mm-panels {
  top: 65px;
}

.mm-panel {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
  width: 100%;
}

.mm-panels > .mm-panel > .mm-listview {
  padding: 0 20px;
}

.mm-navbars_top>.mm-navbar,
.mm-navbars_top>.mm-navbar:not(.mm-navbar_has-btns) {
  height: auto;
  padding-right: 30px;
}

.menu__close {
  display: inline-block;
  margin-right: 8px;
  text-align: right;

  &:after {
    content:url(/html/images/icon-menu-close.svg);
    position: absolute;
    top: 14px;
    width: 14px;
    height:14px;
  }

  a,
  a:active,
  a:hover,
  a:link,
  a:visited {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-right: 8px
  }
}

.mm-navbar {
  position: absolute;
}

.mm-navbars_top {
  border-bottom: 0;
}

.mm-navbar__title,
.mm-navbar_has-btns {
  display: none!important;
}

.mm-page__blocker {
  background: rgba(0, 0, 0, 0.20);
}

.mm-menu .mm-listitem_opened>.mm-btn_next,
.mm-menu .mm-listitem_opened>.mm-panel {
  background: transparent;
}
