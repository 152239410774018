.author {
	&--horizontal {
		display: grid;
		gap: 15px;
		grid-template-columns: 250px 1fr;
		margin-top: 25px;
	}

	&__image {
		margin-bottom: 20px;
		width: 100%;

		img {
			margin: auto;
		}
	}

	&__bio {
	}

	&__sub {
		font-size: 1.125rem;
	}

	&__name {
		font-size: 1.5rem;
		margin-bottom: 20px;

		@include tablet-above {
			font-size: 1.875rem;
		}
	}

	&__url {
		text-decoration: none;
	}

	&__description {
		font-size: 1.5rem;
		line-height: 2.125rem;
		margin-bottom: 1rem;

		@include tablet-above {
			font-size: 1rem;
			line-height: 1.5rem;
		}
	}
}
