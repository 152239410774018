.full-width {
  background-color: #FCF3F3;

  @include desktop {
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
  }
}

.page404-container {
  @include desktop {
    z-index: 8;
  }
}

.page404 {
  background-size: cover;

  .overlay-content {
    @include desktop {
      margin-left: 50%;
    }

    h2 {
      color: black;
      @include desktop {
        margin-top: 0;
      }
    }

    p {
      color: black;
      font-size: 16px;
      line-height: 25px;
    }
  }

  .search-overlay-container {
    @include desktop {
      position: relative;
      top: 33%;
      z-index: 9;
    }
  }

  .search-form {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;

    .search-form__input-wrapper {
      forder: 2px solid red;
      input {
        font-size: 16px;
        height: 48px;
        line-height: 160%;
        letter-spacing: 0.16px;
      }
    }

    .search-form__reset-button {
      display: none;
    }

    button {
      font-size: 16px;
      height: 48px;
      padding: 12px 24px;
      border-radius: 24px;
    }
  }
}

.category-actions {
  margin-bottom: 20px;

  @include desktop {
    margin-bottom: 30px;
  }
}

.container-oops {
  @include desktop {
    position: relative;
  }
}

.oops {
  @include desktop {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  img {
    @include desktop {
      max-height: 100%;
      width: auto;
    }
  }
}

#page404-copyright {
  font-size: 1rem;
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #fff;
  z-index: 9;
  opacity: .6;
}

#page404-copyright a {
  color: inherit;
}
