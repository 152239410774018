@import 'helper/mixins.scss';

html {
	@include tablet-below {
		--grid-spacing: 20px;
		--grid-spacing-inverted: -20px;
	}
	@include desktop {
		--grid-spacing: 15px;
		--grid-spacing-inverted: -15px;
	}

	--grid-max-width: 1170px;
}

@import 'helper/colors.scss';
@import 'helper/fonts.scss';
@import '_old-prod.scss';
@import '_old-from-html.scss';
@import 'base.scss';
@import '_spacings.scss';

html {
	font-size: 16px;
	@media (max-width: 389px) {
		font-size: 3.33vw;
	}
}

@import 'components/ads.scss';
@import 'components/search.scss';
@import 'components/footer.scss';
@import 'components/images.scss';
@import 'components/navbar-subheader.scss';
@import 'components/tag-list.scss';
@import 'components/article.scss';
@import 'components/images.scss';
@import 'components/breadcrumb.scss';
@import 'components/wysiwyg.scss';
@import 'components/newsletter.scss';
@import 'components/input.scss';
@import 'components/button.scss';
@import 'components/slider.scss';
@import 'components/socials.scss';
@import 'components/teaserlist.scss';
@import 'components/header.scss';
@import 'components/header-slider.scss';
@import 'components/category-teasers.scss';
@import 'components/category-slider.scss';
@import 'components/table-of-contents.scss';
@import 'components/category-pages';
@import 'components/gallery.scss';
@import 'components/recipe.scss';
@import 'components/intext-gallery.scss';
@import 'components/newsletterlist.scss';
@import 'components/menu.scss';
@import 'components/ads.scss';
@import 'components/404.scss';
@import 'components/pinterest.scss';
@import 'components/author.scss';
@import 'components/author-list.scss';
@import 'components/reuseableblocks.scss';
