$lifeline-height: 22px;
$header-bar-height: 60px;
:root {
	--header-height: #{$lifeline-height + $header-bar-height};

	@include desktop {
		--header-height: 118px;
	}
}

.lifestyle-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 25px;
	@include tablet-below {
		background: #fff;
		border-bottom: 1.5px solid #fcf3f3;
		grid-column: 1 / span 2;
		grid-row: 1;
	}

	@include desktop {
		grid-column: 2 / span 1;
		grid-row: 1;
		justify-content: flex-end;
		padding-top: 16px;
		margin-bottom: auto;
	}
}

.sticking .lifestyle-wrapper {
	@include tablet-below {
		border-bottom-color: transparent;
	}
}

.lifestyle-logo {
	img {
		@include tablet-below {
			height: $lifeline-height;
			transform: translateX(-7px);
			filter: saturate(0.1);
		}
		@include desktop {
			width: 95px;
			transform: translateX(6px);
		}
	}

	@include desktop {
		transition: transform 0.3s;
	}
}

.sticking .lifestyle-logo {
	@include desktop {
		transform: translateY(9px);
	}
}

#navbar {
	display: flex;
	align-items: center;

	@include desktop {
		width: 100%;
		justify-content: flex-end;
		align-items: flex-start;
		transition: margin 0.3s;
	}

	&::after {
		@include desktop {
			content: '';
			flex-grow: 0;
			transition: all 0.3s;
		}
	}
}

.sticking #navbar {
	@include desktop {
		margin-right: 100px;
		transition: margin 0.3s;
		will-change: flex-grow;
	}

	&::after {
		@include desktop {
			content: '';
			flex-grow: 1;
		}
	}
}

.navbar-header {
	position: relative;
	z-index: 9;
	display: grid;
	grid-template-columns: auto 1fr;
}

.hamburger-inner {
	font-size: 16px;
	line-height: 20px;
	color: $color-black-soft;
	display: flex;
	align-items: center;
	margin-right: 30px;

	&:hover {
		text-decoration: none;
	}

	svg {
		margin-left: 10px;
	}
}

/* LOGO */
.navbar-brand {
	display: block;
	z-index: 1000;

	@include tablet-below {
		height: $header-bar-height;
		width: 161px;
		margin-bottom: -24px;
	}

	@include desktop {
		transition: height 0.3s, margin 0.3s;
		height: 119px;
		width: 105px;
		margin-bottom: -64px;
		grid-row: 1 / span 2;
		will-change: margin, height;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}

	> img {
		display: block;
		max-width: 100%;
		max-height: 100%;

		@include tablet-below {
			max-height: 80px;
		}
	}
}

.sticking .navbar-brand {
	@include desktop {
		height: 79px;
		margin-bottom: -44px;
	}
}

.navbar-brand-wrapper {
	display: inline-block;
	margin: 0;
	z-index: 999;

	@include desktop {
		grid-row: 1 / span 2;
		grid-column: 1 / span 1;
	}
}

/* SEARCH */
.js-toggle-search {
	position: relative;
}

#search-overlay .search-overlay-container {
	top: 42.5%;
}

#search-overlay .overlay-content {
	margin-top: 38px;
}

.header__search-toggle {
	appearance: none;
	padding: 6px;
	border: 0;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	display: flex;
	position: relative;

	@include desktop {
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 10px;
		margin-right: 10px;
		transform: translateY(-5px);
	}

	&:focus {
		outline: 1px solid black;
	}
}

.header__search-icon,
.header__search-close-icon {
	width: 20px;
	height: 20px;
	transition: opacity 0.3s, transform 0.3s;
}

.header__search-close-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0;
	transform: scale(0) translate(-50%, -50%);
}

.has-open-search {
	.header__search-icon {
		opacity: 0;
		transform: scale(0);
	}
	.header__search-close-icon {
		opacity: 1;
		transform: scale(1) translate(-50%, -50%);
	}
}

.search-overlay__close-button {
	appearance: none;
	border: 0;
	background: none;
	padding: 0;
	margin: 0;
	height: 32px;
	width: 32px;

	@include tablet-below {
		display: none;
	}
}

.search-overlay__close-button svg {
	width: 20px;
	height: 20px;
}

.search-overlay__header {
	margin-top: 80px;
}

.search-overlay__header span {
	font-size: 28px;
	line-height: 1.14;
}

.search-overlay__header button + span {
	margin-left: 20px;
}

#search-overlay form {
	@include desktop {
		display: flex;
		gap: 17px;
	}
}

.navbar-brand-search {
	height: 20px;
	@include tablet-below {
		transform: translateX(2px);
	}
}

.navbar-brand-search--mobile {
	@include desktop {
		display: none;
	}
}

.navbar-brand-search .icon-icon-search {
	color: $color-black;
}

.navbar-brand-search .icon-icon-search:before {
	top: 0;
}

@include mobile {
	.navbar > .container .navbar-brand {
		position: static;
	}
}

.header__bar {
	display: flex;
	justify-content: end;
	align-items: center;
	align-self: flex-end;

	@include tablet-below {
		padding-right: 22px;
		height: $header-bar-height;
	}

	@include desktop {
		align-items: start;
		transition: transform 0.3s;
		grid-row: 1;
		grid-column: 2;
		margin-top: 70px;
		max-width: calc(1200px - 2 * 15px - 161px);
		flex-direction: row-reverse;
	}
}

.sticking .header__bar {
	@include desktop {
		transform: translateY(-40px);
	}
}

.navigation__toggle-button {
	color: $color-black-soft;

	&:hover,
	&:focus {
		text-decoration: none;
		color: inherit;
	}

	@include tablet-below {
		margin-right: 23px;
		display: flex;
		justify-content: center;
		margin-top: 2px;
	}

	@include desktop {
		span {
			display: none
		}
	}

	svg {
		margin-left: 8px;

		@include desktop {
			margin-left: 12px;
			margin-top: 4px;
		}
	}
}

.navigation__items {
	margin: 0;

	@include desktop {
		transition: all 0.3s;
		overflow: auto;
		justify-content: flex-start;
	}

	&::before,
	&::after {
		content: none;
	}
}
.sticking .navigation__items {
	@include desktop {
		justify-content: flex-start;
	}
}

.dropdown-menu {
	z-index: 990;
	transform: translateY(calc(-1 * var(--scrollbar-height, 0px) - 1px));

	&::before {
		content: '';
		background: $color-white;
		width: 100vw;
		height: 100%;
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		box-shadow: 100vw 0 0 0 $color-white;
		z-index: -1;
	}

	&::after {
		content: '';
		pointer-events: none;
		background: rgb(0 0 0 / 35%);
		width: 50vw;
		height: 100vh;
		display: block;
		top: 150px;
		position: fixed;
		left: 0;
		z-index: -2;
		box-shadow: 50vw 0 0 0 rgb(0 0 0 / 35%), -50vw 0 0 0 rgb(0 0 0 / 35%);
	}
}

.navigation__item-link {
	color: black;
	font-weight: 600;
	font-size: 18px;
	border-bottom: 4px solid var(--border-color, transparent);
	white-space: nowrap;

	@include desktop {
		padding-bottom: 23px;
		padding-top: 4px;
		line-height: 1;
		text-align: center;
	}

	&:hover {
		text-decoration: none;
		--border-color: #{$color-primary};
		color: $color-black;
	}
}

.active > .navigation__item-link {
	--border-color: #{$color-primary};
}

.navbar-nav-menu {
	display: flex;
	width: 100%;

	@include desktop {
		transition: margin 0.3s;
	}

	li {
		flex-grow: 1;
	}
}

.header__search-button-wrapper {
	@include desktop {
		display: flex;
		justify-content: flex-end;
		transition: all 0.3s;
	}
}

.mm-menu {
	background-color: #fcf3f3;
}
.mm-listitem {
	@extend .font-bold;
	font-size: 18px;
}

.mm-page__blocker {
	background: rgb(136 124 124 / 88%);
	transition: transform 0.4s ease, background-color 0.4s;
}

.mm-wrapper_opened:not(.mm-wrapper_opening) .mm-page__blocker {
	background: rgb(136 124 124 / 0%);
}

#search-overlay {
	background-color: $color-white;
}
.has-sticking-header:not(.content--search) #search-overlay {
	background-color: #fcf3f3;
}

#search-overlay.active {
	pointer-events: all;
}
.search-overlay__input {
	position: relative;
	background: rgba(0, 0, 0, 0.035);
	border-color: rgba(0, 0, 0, 0.035);

	@include tablet-below {
		margin-bottom: 16px;
	}

	@include desktop {
		flex-grow: 1;
		max-width: none;
		width: 100%;
	}

	input {
		z-index: 1;
	}

	svg {
		position: absolute;
		pointer-events: none;
		z-index: 2;
		right: 18px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.search-overlay__submit {
	justify-content: center;

	@include desktop {
		justify-content: center;
		padding: 8px 24px;
		width: auto;
		min-width: 182px;
	}

	svg {
		margin-left: 10px;
	}
}

.lifestyle-navigation {
	@include tablet-below {
		display: none;
	}
	@include desktop {
		margin-right: 34px;
		margin-top: 7px;
		transition: transform 0.3s, opacity 0.3s;
		transform-origin: right center;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		display: flex;
		gap: 24px;
		font-size: 14px;
		line-height: 1.43;

		@include desktop {
			gap: 25px;
		}
	}

	a {
		color: black;
		opacity: 0.6;
		transition: opacity 0.3s;

		&:hover,
		&:focus {
			opacity: 1;
			text-decoration: none;
		}
	}
}

.sticking .lifestyle-navigation {
	@include desktop {
		transform: translateY(-200%);
		opacity: 0;
	}
}

/* DROPDOWN SUBMENU */
.navigation__items .open .dropdown-menu {
	display: flex;
	gap: 32px;
}

.navigation__subnavigation {
	margin: -5px 0;
	padding: 0;
	list-style: none;
	flex-grow: 2;
	display: flex;
	padding-top: 8px;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: auto;
	gap: 26px 0;

	a {
		font-size: 16px;
		color: black;
	}
	li {
		width: 100%;
		padding-left: 28px;
	}
}

.navigation__subnavigation--two-columns li {
	width: 50%;
}

.navigation__nav-teaser {
	flex-grow: 1;
	min-width: 268px;
	max-width: 268px;
	position: relative;

	img {
		width: 100%;
		aspect-ratio: 16 / 10;
		overflow: hidden;
	}
}

//hide first 6 elements in desktop burgermenu
.navbar-nav-menu .dropdown{
	display: none;

	&:nth-child(-n+4){
		display: inline;
	}
}
