#footer {
	background-color: $color-primary;
	color: $color-white;
	margin-top: 77px;

	@include tablet-below {
		padding-top: 51px;
		padding-bottom: 33px;
	}

	@include desktop {
		padding-top: 53px;
		padding-bottom: 56px;
	}
}
.newsletter-cta-container + #footer {
	@include tablet-below {
		margin-top: 0;
	}
}

.footer__socials {
	display: flex;
	gap: 16px;
	flex-wrap: wrap;

	@include desktop {
		grid-column: 1 / span 1;
	}

	a {
		display: flex;

		@include tablet-below {
			width: 40px;
			height: 40px;
		}
		@include desktop {
			width: 32px;
			height: 32px;
		}
	}
}

.footer__grid {
	display: grid;

	@include desktop {
		grid-template-columns: 3fr 2fr;
	}
}

.footer__navigation {
	@include tablet-below {
		margin-top: 26px;
	}

	@include desktop {
		grid-column: 2 / span 1;
		margin-top: 7px;
	}

	ul {
		padding-left: 0;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		gap: 13px 24px;
		margin-bottom: 0;

		@include desktop {
			justify-content: flex-end;
		}
	}

	li {
		@include mobile {
			flex-grow: 1;
			width: calc(50% - 12px);
		}
	}

	a {
		@extend .p-m;
		@extend .font-bold;
		color: $color-white;
	}
}

.footer__funke-logo {
	margin-top: 56px;

	@include desktop {
		grid-column: 1 / span 1;
	}
}

.footer__funke-logo-text {
	display: block;
	margin-top: 18px;

	@include desktop {
		margin-top: 24px;
	}
}

.footer__copyright {
	margin-top: 56px;
	display: block;

	@include desktop {
		grid-column: 2 / span 1;
		justify-content: flex-end;
		align-items: flex-end;
		display: flex;
	}
}
