$color-primary: #ef0008;
$color-primary-dark: #ba1b1b;
$color-secondary: #a20b41;
$color-secondary-dark: #741d3c;
$color-secondary-soft: rgba(180, 24, 80, 0.1);
$color-secondary-soft-dark: rgba(180, 24, 80, 0.2);
$color-white: #ffffff;
$color-black: #000000;
$color-black-light: #515151;
$color-medium-grey: #eaeaea;
$color-black-soft: #060606;

.text-primary {
	color: $color-primary;
}
a.text-primary:hover,
a.text-primary:focus {
	color: $color-primary-dark;
}

.text-secondary {
	color: $color-secondary;
}
a.text-secondary:hover,
a.text-secondary:focus {
	color: #286090;
}

.text-white {
	color: $color-white;
}
.text-black {
	color: $color-black;
}
a.text-black:hover,
a.text-black:focus {
	color: $color-black;
}

.bg-white {
	background-color: $color-white;
}
