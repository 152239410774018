h1,
h2,
h3,
h4,
h5,
h6 {
	@include font-bold;
}

body {
	padding-top: calc(var(--header-height));
}

#page,
.page {
	background-color: #fff;
}

.append-inner {
	margin-top: 24px;
}

.mm-page {
	background: white;
	max-width: 1170px;
	margin: auto;
}
