.pimcore_area_teaserlist + .ad--superbanner,
.pimcore_area_teaserslider + .ad--superbanner,
.pimcore_area_content + .ad--superbanner {
	margin-top: 2rem;
}

.billboard-btf-ads {
	display: none;

	@include desktop {
		display: block;
	}
}

/**
new styles
 */
.ad-wrapper {
	margin: 20px 0 25px;
	position: relative;

	@include mobile {
		margin: 25px 0;
	}

	&--sb1 {
		position: relative;
		min-height: 280px;
	}

	&--pos1 {
		position: relative;
		min-height: 150px;
	}

	&--sky1 {
	}

	&--sb1,
	&--bibo,
	&--superbanner,
	&--billboard,
	&--billboard_btf,
	&--billboard_btf_2,
	&--billboard_btf_3 {
		margin-right: auto;
		margin-left: auto;

		@include tablet-above {
			width: 750px;
		}
		@media (min-width: 992px) {
			width: 940px; //970px - 2 x 15px padding;
		}
		@include desktop {
			width: 1140px; //1170px - 2 x 15px padding
		}
	}

	//superbanner has no margin because it may become a fireplace ad-
	&--sb1,
	&--superbanner {
		@media (min-width: 992px) {
			width: 970px;
		}
		@include desktop {
			width: 1170px;
		}
	}

	&--billboard_btf {
		padding-right: 0;
		padding-left: 0;
		text-align: center;
	}

	.ad {
		text-align: center;

		&--sb1,
		&--pos1 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&--taboola-eoa {
			margin: 0 15px;
		}
	}

	.ad-wrapper {
		padding: 0;
		background-color: transparent;

		&:before {
			display: none;
		}
	}

	&--mobile {
		@include desktop {
			display: none;
		}
	}

	&--tablet {
		@include desktop {
			display: none;
		}
	}

	&--desktop {
		@include mobile {
			display: none;
		}
		@include tablet {
			display: none;
		}
	}

	&--mrec {
		min-height: 280px;
	}

	&--pos {
		min-height: 280px;
	}

	&--po1 {
		min-height: 280px;

		@include desktop {
			min-height: 350px;
		}
	}

	&--superbanner,
	&--superbanner {
		min-height: 250px;
	}

	&--sky_right {
		display: flex;
	}

	//show only for elements that have actually loaded an ad
	&:has(.ad div):before {
		content: 'Anzeige';
		text-transform: uppercase;
		position: absolute;
		top: -18px;
		font-size: 9px;
		color: #403f3f;
		margin-top: 2px;
	}

	//hide for ad container with an element that ist display: none
	&:has(div[style*='display: none']):before {
		content: '';
	}

	// BDF-199 Hide ad labels in sky scrapers and navbars
	&--navbar_left:has(.ad div):before,
	&--navbar_right:has(.ad div):before,
	&--sky_left:has(.ad div):before,
	&--sky_right:has(.ad div):before {
		content: none;
	}

	&--outofpage:has(div div):before {
		content: none;
	}
}

//sticky elements
.ad-wrapper.sticky .ad.ad--sky_right {
	position: fixed;
	top: 135px;
}

//homepage billboard ads
.category-teaser-group .ad-wrapper--bibo.ad-wrapper--desktop {
	@include desktop {
		margin-top: 50px;
		margin-bottom: 0;
	}
}

.category-teaser-group .ad-wrapper--pos.ad-wrapper--mobile {
	@include mobile {
		margin-bottom: 0;
	}
}

//category page superbanner
.category-ads--top .ad-wrapper--superbanner {
	@include desktop {
		margin-bottom: 0;
	}
}

//article page sidebar right
.sidebar__inner .ad-wrapper--mrec {
	@include desktop {
		margin-top: 40px;
		margin-bottom: 0;
	}
}

.superbanner {
	@include desktop {
		margin-top: 20px;
	}
}

.ad-wrapper-placeholder {
	align-items: center;
	background-color: #f6f6f6;
	background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='lightgrey' stroke-width='1'/><path d='M0 0 L100 100 ' stroke='lightgrey' stroke-width='1'/></svg>");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100% 100%, auto;
	color: #a0a0a0;
	display: flex;
	font-family: Inter-Latin-Bold, serif;
	justify-content: center;
	margin: 20px 0 25px;
	min-height: 100px;
	position: relative;
	width: 100%;

	@include mobile {
		margin: 25px 0;
	}
}
