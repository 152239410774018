.breadcrumb {
	list-style-type: none;
	margin-top: 2px;
	margin-bottom: 24px;
	padding-left: 0;
	font-size: 14px;
	line-height: 20px;
	color: $color-black-light;

	@include tablet-below {
		display: none;
	}
	@include desktop {
		display: flex;
		flex-wrap: wrap;
	}

	a {
		color: $color-secondary;
	}

	li + li::before {
		content: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_640_1834)'%3E%3Cpath d='M2.33203 0.667969L5.66537 4.0013L2.33203 7.33464' stroke='%23A20B41' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_640_1834'%3E%3Crect width='8' height='8' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
		margin-left: 8px;
		margin-right: 8px;
	}
}
