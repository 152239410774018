.article + .gallery {
	@include tablet-below {
		margin-top: 20px;
	}
}

.gallery {
	@include desktop {
		display: grid;
		gap: 0 132px;
		grid-template-columns: 1fr 368px;
		grid-template-rows: auto 1fr;
	}
}

.gallery__images-slider {
	width: 100%;
}
.gallery__images-slider-wrapper {
	grid-column: 1 / span 1;
	grid-row: 1 / span 2;
	max-width: 100%;
	overflow: hidden;
}
.gallery__controls {
	grid-column: 2 / span 1;
	grid-row: 1 / span 1;
	display: flex;
	justify-content: space-between;

	@include tablet-below {
		margin-top: 26px;
	}
	@include desktop {
		margin-top: 2px;
	}

	span {
		margin-left: 8px;
		margin-right: 8px;
	}
}
.gallery__metadata-slider {
	grid-column: 2 / span 1;
	grid-row: 2 / span 1;
}
.gallery-headline {
	@include tablet-below {
		margin-top: 18px;
	}
	@include desktop {
		margin-top: 30px;
	}
	margin-bottom: 9px;
}

.gallery__description {
	color: #515151;
	margin-top: 50px;

	@include desktop {
		max-width: calc(100% - 500px);
	}
}

.gallery__footer {
	@include tablet-below {
		margin-top: 52px;
	}
	@include desktop {
		max-width: calc(100% - 500px);
		margin-top: 70px;
	}
	display: grid;
	grid-template-columns: 1fr auto;

	hr {
		margin-top: 0;
		width: 100%;
		grid-column: 1 / span 2;
		grid-row: 1;

		@include tablet-below {
			margin-bottom: 24px;
		}

		@include desktop {
			margin-bottom: 32px;
		}
	}

	.article-published {
		grid-column: 1 / span 1;
		grid-row: 2;

		@include tablet-below {
			font-size: 12px;
			line-height: 16px;
		}
	}

	.article-authors {
		grid-column: 1 / span 1;
		grid-row: 3;
		color: #242424;
		line-height: 28px;

		@include tablet-below {
			font-size: 16px;
			margin-top: 24px;
		}
		@include desktop {
			font-size: 18px;
			margin-top: 58px;
		}
	}

	.article-meta {
		grid-column: 2 / span 1;
		grid-row: 2;
	}

	.socials {
		margin-top: 0;

		a:last-child {
			margin-right: 0;
		}

		a,
		img {
			width: 32px;
			height: 32px;
		}
	}
}

.gallery__image-item {
	position: relative;
}

.gallery__image-item-copyright {
	@extend .article-media__source;
}

.pimcore_area_imagegallery {
	@include tablet-below {
		margin-top: 32px;
	}
	@include desktop {
		margin-top: 48px;
	}
}

.isGallery:after,
.article-gallery.article-gallery--gallery .article-media:after {
	@extend .font-bold;
	position: absolute;
	z-index: 3;
	top: 50%;
	left: 50%;
	display: block;
	margin: 0 auto;
	padding: 12px 24px 12px 60px;
	content: 'Bildergalerie';
	transform: translate(-50%, -50%);
	pointer-events: none;
	color: #fff;
	background-image: url(/html/svgs/icon-gallery.svg);
	background-repeat: no-repeat;
	background-position: left 26px center;
	background-color: #ef0008;
	border-radius: 56px;

	@include tablet-below {
		font-size: 16px;
		line-height: 24px;
	}
	@include desktop {
		font-size: 18px;
		line-height: 24px;
	}
}
.navbar-gallery-btn {
	@extend .font-bold;
	display: block;
	margin: 0 auto;
	padding: 12px 24px 12px 60px;
	z-index: 3;
	pointer-events: none;
	color: #fff;
	background-image: url(/html/svgs/icon-gallery.svg);
	background-repeat: no-repeat;
	background-position: left 26px center;
	background-color: #ef0008;
	border-radius: 56px;
	margin-top: 12px;
	margin-left: 12px;

	@include tablet-below {
		font-size: 16px;
		line-height: 24px;
	}
	@include desktop {
		display: none;
	}
}
