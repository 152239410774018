.main-content {

	.pimcore_area_content:first-child {
		margin-top: 25px;
	}

	.pimcore_area_content:last-child {
		@include tablet-below {
			margin-bottom: 91px;
		}
		@include desktop {
			margin-bottom: 119px;
		}
	}

	.pimcore_area_content + .pimcore_area_content {
		margin-top: 25px;
		margin-bottom: 50px;
	}
}

.header-slider__wrapper + .main-content {
	@include tablet-below {
		margin-top: 40px;
	}
	@include desktop {
		margin-top: 70px;
	}
}
