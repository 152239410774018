.content--search #page #search-overlay {
	position: static;
	visibility: visible;
	width: auto;
	height: auto;
	margin: 5rem 0;
	opacity: 1;
	pointer-events: initial;
}

.content--search #page .search-results {
	margin: 5rem 0 0;
}

.content--search #page .search-result-body {
	position: relative;
}

body.content--search .navbar-ads-left,
body.content--search .navbar-ads-right {
	display: none;
}

.search-form__input-wrapper {
	display: flex;
	flex-grow: 1;
	position: relative;

	input {
		padding-right: 50px;
	}
}

.search-form__reset-button {
	position: absolute;
	width: 20px;
	height: 20px;
	right: 14px;
	top: 14px;
	background: #d9d9d9;
	border-radius: 48px;
	padding: 0;
	border: 0;
	line-height: 1;
}
input:placeholder-shown ~ .search-form__reset-button {
	display: none;
}

.search-result {
	display: flex;
	align-items: center;
}

.search-result-headline {
	margin-top: 0.25rem;
	@include tablet-below {
		font-size: 18px;
		line-height: 1.56;
	}
	@include desktop {
		font-size: 20px;
		line-height: 1.6;
	}
}

.search-result-media img {
	@include tablet-below {
		max-width: 112px;
	}
	@include desktop {
		max-width: 168px;
	}
}
