%button {
	background: var(--bg);
	border-radius: 64px;
	padding: 8px 16px 8px 24px;
	display: flex;
	min-height: 48px;
	color: $color-white;
	font-size: 18px;
	line-height: 1.56;
	appearance: none;
	border: 0;
	font-weight: normal;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	transition: background-color 0.1s;
	@include font-bold;
	transition: color 0.2s, background-color 0.2s;

	&:focus,
	&:active,
	&:hover {
		background: var(--bg-hover);
	}

	&:disabled {
		color: --color-disabled;
	}
}
.button--red {
	@extend %button;
	--bg: #{$color-primary};
	--bg-hover: #b61415;
	--color-disabled: #f4a3a3;
	--color: #{$color-white};
	--color-hover: #{$color-white};
}

.button--black {
	@extend %button;
	padding: 8px 24px 8px 24px;
	--bg: #{$color-black-soft};
	--bg-hover: #{$color-black-light};
	--color-disabled: #999999;
	--color: #{$color-white};
	--color-hover: #{$color-white};
}

a:focus,
a:active,
a:hover {
	&:has(button) {
		text-decoration:none;
	}
}

.button--icon-only {
	padding: 0;
	min-height: 0;
}
