.article-container {
	@include tablet-below {
		margin-top: 32px;
	}
	@include desktop {
		margin-top: 80px;
	}
}

.article-overline {
	margin-bottom: 8px;
}

.article-title {
	@include tablet-below {
		margin-bottom: 12px;
		margin-top: 12px;
	}
	@include desktop {
		margin-bottom: 20px;
		margin-top: 10px;
	}
}

.gallery__counter {
	line-height: 1.25;
	color: $color-secondary;
	@include font-bold;

	@include tablet-below {
		font-size: 24px;
		line-height: 1;
		display: flex;
		align-items: center;
	}
	@include desktop {
		font-size: 32px;
	}

	&::before {
		content: attr(data-before);
	}
}

.article-caption {
	margin-top: 8px;
	font-size: 16px;
	line-height: 1.5;
	color: $color-black-light;
}

#bt-player + .pimcore_area_wysiwyg {
	margin-top: 48px;
}

.pimcore_area_wysiwyg + .pimcore_area_embedder {
	margin-top: 30px;
}

.pimcore_area_embedder + .pimcore_area_wysiwyg {
	margin-top: 30px;
}

.article__content {
	@include desktop {
		//padding-right: 28px;
	}
}

.article-published,
.article-authors {
	font-size: 14px;
	line-height: 1.5;
	color: $color-black-light;
	opacity: 0.7;
}

.article-authors {
	padding: 15px;
}

.article__header-meta {
	@include desktop {
		display: none;
	}
}

.article-sources__wrapper {
	margin-top: 30px;
	font-size: 20px;
	grid-column: 1 / span 2;
	grid-row: 4;

	@include tablet-below {
		margin-bottom: 30px;
	}
}

.article-sources__wrapper svg {
	display: none;
}

.article-source {
	margin: 0 0 24px;
}

.article-gallery {
	margin-bottom: 24px;
	position: relative;
}

#sidebar .category-slider-headline {
	@extend .p-xl;
}
#sidebar .category-slider__arrows {
	@extend .slider-arrows--small;
}

.article-title + .article-gallery {
	margin-top: 24px;
}

.article-title + .description {
	margin-top: 30px;
}

.description + .article__header-meta + .article-gallery {
	margin-top: 30px;
}

.toc + .sidebar__ad-wrapper {
	margin-top: 12px;
}

.sidebar__ad-wrapper + .pimcore_area_handpickedpages {
	margin-top: 42px;
}

.article-gallery {
	margin-bottom: 24px;
}

.article-gallery + .article__content {
	@include tablet-below {
		margin-top: 45px;
	}
	@include desktop {
		margin-top: 54px;
	}
}
