@use 'sass:math';

.category-teasers {
	$gap: 2rem;
	display: grid;
	gap: $gap;
	grid-template-columns: repeat(1, 1fr);

	&:not(.slick-slider) {
		@include desktop {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.slick-slide {
		@include desktop {
			margin: 0 math.div($gap, 2);
		}
	}

	.slick-list {
		@include desktop {
			margin: 0 -#{math.div($gap, 2)};
		}
	}

	.ad-wrapper,
	.superbanner {

		@include desktop {
			grid-column: 1 / span 3;
		}

		//the ads create an element with the class .container that interferes with another definition
		.container {
			width: initial;
		}
	}

	img {
		width: 100%;
	}

	@include tablet-below {
		.slick-list {
			padding: 0 20% 0 0 !important;
			margin: 0 -20px;
		}
		.slick-slide {
			padding-left: var(--grid-spacing);
			// prevent 1px line hack:
			padding-right: 1px;
		}
	}
}

.category-teaser-description {
	font-size: 16px;
	line-height: 1.625rem;
	margin-top: 0.5rem;
	color: $color-black;

	@include desktop {
		font-size: 16px;
	}
}

.category-teaser-body {
	color: $color-black;

	&:hover {
		text-decoration: none;

		.category-teaser-headline {
			color: $color-secondary;
		}
	}
}

.category-teaser-overline {
	font-size: 11px;

	@include tablet-below {
		margin-top: 1.5rem;
	}

	@include desktop {
		font-size: 12px;
		margin-top: 1.5rem;
	}
}

.category-teaser-headline {
	margin-top: 0.5rem;
	font-size: 16px;
	line-height: 1.2;

	@include desktop {
		font-size: 18px;
	}
}

.category-teaser--large {
	display: grid;

	.category-teaser-body {
		background: #fcf3f3;

		@include mobile {
			display: flex;
			flex-direction: column;
			padding: 0 16px 24px;
		}
	}

	> .category-teaser-group__headline {
		.category-teaser-group__headline-link {
			text-align: left;
			font-size: 32px;
			line-height: 1.25;
			margin-bottom:24px;
			margin-top: 0;

			@include desktop {
				font-size: 55px;
			}
		}
	}

	.category-teaser-group__headline {
		@include mobile {
			grid-area: 1 / 1 / 2 / 2;
		}
	}

	.category-teaser-media {
		@include mobile {
			grid-area: 2 / 1 / 3 / 2;
		}
	}

	.category-teaser-body {
		@include mobile {
			grid-area: 3 / 1 / 4 / 2;
		}
	}

	@include mobile {
		margin-bottom: 24px;
	}

	@include tablet-above {
		grid-column: 1 / 3 span;
		grid-template-columns: 54% 10% 36%;
		grid-template-rows: 1fr auto;
		min-height: min(43.35vw, 32.625rem);

		.category-teaser-media {
			grid-column: 1 / span 2;
			grid-row: 1 / span 2;
			display: block;
			height: 100%;

			picture,
			img {
				display: block;
				height: 100%;
			}

			img {
				object-fit: cover;
			}
		}

		.category-teaser-media {
			grid-column: 1 / span 2;
			grid-row: 1 / span 2;
			z-index: 1;
		}

		.category-teaser__group-headline-wrapper {
			grid-column: 3 / span 1;
			grid-row: 1 / span 1;
			padding-left: 2.5rem;
			padding-bottom: 0.5rem;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
		.category-teaser-body {
			grid-column: 2 / span 2;
			grid-row: 2 / span 1;
			padding: 2rem 0rem 2rem 1.5rem;
			z-index: 2;
		}

		.category-teaser-headline {
			font-size: clamp(22px, 2.46vw, 30px);
			margin-top: 0.5rem;
		}

		.category-teaser-description {
			margin-top: 1.5rem;
		}
	}

	@include desktop {
		grid-template-columns: 60% 6% 34%;

		.category-teaser-body {
			padding: 32px 24px;
		}

		.category-teaser-overline {
			margin-top: 0;
		}

		.category-teaser-headline {
			font-size: 32px;
			line-height: 1.25;
		}

		.category-teaser-description {
			font-size: 18px;
			line-height: 28px;
			margin-top: 16px;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.category-teaser-description:empty {
			display: none;
		}
	}
}

//even nimber, left aligned
.pimcore_area_categoryteaser:nth-child(even)  {
	@include desktop {
		.category-teaser--large {
			grid-template-columns: 34% 6% 60%;

			.category-teaser-body {
				grid-column-start: 1;
				grid-column-end: 3;
			}

			.category-teaser-media {
				grid-column-start: 2;
				grid-column-end: 4;

				img {
					//make image right aligned
					margin-left: auto;
				}
			}
		}

		.mainteaser .category-teaser-group__headline-link {
			text-align: left;
		}
	}
}

.category-teaser-group {
	.category-teaser-slider .category-teaser-description {
		@include tablet-above {
			display: block;
		}
	}
	> .category-teaser-group__headline {
		display: block;
		margin-bottom: 32px;
	}
	.category-teaser-description {
		@include mobile {
			display: none;
		}
	}
	.category-teaser-slider__header {
		@include mobile {
			display: none;
		}
	}

	&__headline {
		margin-top: 0;
		margin-bottom: 0;
	}

	&__headline-link {
		font-family: SourceSerifPro;

		@include tablet-below {
			font-size: 32px;
			line-height: 1.25;
		}

		@include desktop {
			font-size: 55px;
			line-height: 1;
		}

		&:hover {
			text-decoration: none;
		}
	}
}

.mainteaser {
	.category-teaser-group__headline-link {
		text-align: right;
		font-size: min(4.65vw, 56px);
		display: block;
	}
}

.category-teaser-group__headline + .category-teasers {
	@include tablet-below {
		margin-top: 16px;
	}

	@include desktop {
		margin-top: 40px;
	}
}

.category-teaser-slider .category-teaser-description {
	@include tablet-below {
		display: none;
	}
}

.pimcore_area_categoryteaser:not(:first-child) {
	@include tablet-below {
		margin-top: 48px;
	}
	@include desktop {
		margin-top: 55px;
	}
}

.pimcore_area_categoryteaser .ad--superbanner {
	margin-bottom: 3rem;
}

.category-teaser--lined {
	background: #fff;
	margin: 40px 0;
	padding: 0;

	.category-teaser--large {
		display: block;
	}

	.category-teaser-overline,
	.category-teaser-headline,
	.category-teaser-media,
	.category-teaser-description p:last-child
	{
		padding-left: 15px;
		padding-right: 15px;
	}

	.btn-white {
		background: #f2f2f2;
	}

	.btn-white:hover,
	.btn-white:active,
	.btn-white:focus {
		background: #000;
	}

	.category-teaser-media {
		img {
			width: 100%;
		}

		&:hover::before,
		&:hover::after {
			display: none;
		}
	}

	.category-teaser-overline {
		font-size: 12px;
		line-height: 1.23;
		margin-top: 1.5rem;
		text-transform: uppercase;
		font-weight: normal;
		color: #a20b41;
	}

	.category-teaser-headline {

		font-family: "Inter-Latin-Bold",serif;
		font-size: 18px;
		line-height: 1.56;
		margin-top: 0.5rem;
	}

	.category-teaser-description,
	.category-teaser-description p {
		font-family: "Inter-Latin-Regular",serif;
		font-size: 16px;
		line-height: 1.625rem;
		margin-top: 0.5rem;
	}

	& .category-teaser .row:nth-child(2) {
		display: none;
	}

	.category-teaser-body {
		background-color: transparent;
		padding: 0;
	}

	@mixin mobile {
		padding-bottom: 30px;

		& .category-teaser-overline {
			font-size: 12px;
		}

		& .category-teaser-headline {
			font-size: 24px;
		}

		&.category-teaser-description p:last-child {
			margin-bottom: 0;
		}

		& .category-actions {
			margin-top: 25px;
		}
	}
}
